var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table",class:[
    _vm.itemsLoading ? 'data-table--loading' : '',
    _vm.canBeSelected ? 'data-table--selectable' : '' ]},[_c('v-data-table',{staticClass:"elevation-0 custom-data-table",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":_vm.sortBy.value,"sort-desc":_vm.sortDesc,"search":_vm.search,"hide-default-footer":"","hide-default-header":"","loading":_vm.itemsLoading},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(_vm.itemsLoading)?{key:"body",fn:function(){return [(_vm.$vuetify.breakpoint.lgAndDown)?_vm._l((5),function(item){return _c('devices-table-skeleton-mobile-item',{key:item})}):[_c('devices-table-sort-skeleton'),_c('devices-table-item-skeleton')]]},proxy:true}:null,{key:"no-data",fn:function(){return [_c('TableFullScreenMessage',{attrs:{"title":_vm.$t('message.emptyTable.activeDevices.title'),"desc":_vm.$t('message.emptyTable.activeDevices.description'),"maxWidth":"510px"},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('hostingDomainsNoTextIllustration')]},proxy:true}])})]},proxy:true},{key:"no-results",fn:function(){return [(_vm.search)?_c('DataIteratorNoResultsContainer',{attrs:{"searchTerm":_vm.search},on:{"clearAllFilters":function($event){return _vm.$emit('clearFilters')}}}):_vm._e()]},proxy:true},(!_vm.$vuetify.breakpoint.mobile && _vm.items.length)?{key:"header",fn:function(ref){
  var isMobile = ref.isMobile;
  var props = ref.props;
  var on = ref.on;
return [_c('table-custom-sort',{attrs:{"isMobile":isMobile,"props":props,"canBeSelected":"","allSelected":_vm.allSelected,"v-on":on},on:{"selectAll":_vm.handleSelectAll,"update:checked-items":_vm.handleItemCheck,"update:sortBy":_vm.changeSortBy,"update:sortDesc":_vm.changeSortDesc}})]}}:null,(!_vm.$vuetify.breakpoint.mobile)?{key:"item.select",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('checkbox',{attrs:{"checked":_vm.checkedItems.includes(item.device_id)},on:{"change":function($event){return _vm.handleItemCheck(item)}}})],1)]}}:null,{key:"item.ip_address",fn:function(ref){
  var item = ref.item;
return [_c('b',{staticClass:"heading--text"},[_vm._v(" "+_vm._s(item.ip_address)+" ")])]}},{key:"item.browser",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24"}},[_vm._v(" "+_vm._s(("$" + (_vm.getBrowserIcon(item.browser))))+" ")]),_vm._v(" "+_vm._s(item.browser)+" ")],1)]}},{key:"item.device",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24"}},[_vm._v(" "+_vm._s(("$" + (_vm.getDeviceIcon(item.device))))+" ")]),_vm._v(" "+_vm._s(item.device)+" "),(item.current_device)?_c('status-label',{staticClass:"ml-2",attrs:{"small":"","status":"primary","value":_vm.$t('message.status.device.current')}}):_vm._e()],1)]}},{key:"item.last_login",fn:function(ref){
  var item = ref.item;
return [(!item.session_id)?[_vm._v(_vm._s(item.last_login))]:_c('div',{staticClass:"current-device"},[_vm._v(_vm._s(_vm.$t('message.status.device.online')))])]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex actions-row"},[(item.device_id)?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.showForgetDeviceModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$thrash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.device.forget', {count: 1})))])]):_vm._e(),(item.session_id)?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.showLogoutDeviceModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$logout")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.device.logout')))])]):_vm._e()],1)]}},(_vm.$vuetify.breakpoint.mobile)?{key:"item",fn:function(ref){
  var item = ref.item;
  var headers = ref.headers;
return [_c('devices-table-responsive-item',{attrs:{"item":item,"itemBrowser":_vm.getBrowserIcon(item.browser),"itemDevice":_vm.getDeviceIcon(item.device),"headers":headers,"checked":_vm.checkedItems.includes(item.device_id)},on:{"update:checked":function($event){return _vm.handleItemCheck(item)},"update:check-all":_vm.handleSelectAll,"update:checked-items":_vm.handleItemCheck,"update:sortBy":_vm.changeSortBy,"update:sortDesc":_vm.changeSortDesc,"logoutDevice":function($event){return _vm.showLogoutDeviceModal(item)},"forgetDevice":function($event){return _vm.showForgetDeviceModal(item)}}})]}}:null,(_vm.items.length)?{key:"footer",fn:function(ref){
  var props = ref.props;
return [_c('div',[_c('data-iterator-footer',_vm._g({attrs:{"props":props,"page":props.pagination.page,"numberOfPages":props.pagination.pageCount,"possibleItemsPerPage":_vm.possibleItemsPerPage,"listStyle":0,"itemsPerPage":props.pagination.itemsPerPage,"perPageLabel":_vm.$t('table.footer.device')},on:{"update:itemsPerPage":_vm.changePerPage,"update:page":_vm.changePage}},_vm.$listeners))],1)]}}:null],null,true)}),_c('v-dialog',{ref:"dialog",attrs:{"persistent":_vm.modalOptions.persistent,"transition":"custom-dialog-transition"},model:{value:(_vm.modalOptions.open),callback:function ($$v) {_vm.$set(_vm.modalOptions, "open", $$v)},expression:"modalOptions.open"}},[(!_vm.modalOptions.persistent)?_c('div',{staticClass:"card-overlay",on:{"click":function($event){_vm.modalOptions.open = !_vm.modalOptions.open}}}):_c('div',{staticClass:"card-overlay",on:{"click":function($event){return _vm.$refs.dialog.animateClick()}}}),_c('basic-modal',{key:_vm.modalRender,staticStyle:{"width":"560px"},attrs:{"modalOptions":_vm.modalOptions},on:{"modal-close":_vm.modalClose}})],1),_c('devices-snackbar',{attrs:{"items":_vm.items,"checkedItems":_vm.checkedItems},on:{"update:check-all":_vm.handleSelectAll,"mass-action-forget":function($event){_vm.showMassForgetDeviceModal(
        _vm.items.filter(function (item) {
          return _vm.checkedItems.includes(item.device_id);
        })
      )}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }